import React, { useContext, useRef, useState, useEffect } from 'react';
import styles from './GetCurriculum.module.css';
import { Checkbox } from '@material-ui/core';
import { LangContext } from '../../containers/context/LangContext';

const GetCurriculum = ({
  state,
  setState = () => {},
  onSubmitHandler = () => {}
}) => {
  const { translations } = useContext(LangContext);
  const [contentHeight, setContentHeight] = useState(null);

  const modalContentRef = useRef(null);

  useEffect(() => {
    setContentHeight(modalContentRef?.current?.offsetHeight);
  }, []);

  return (
    <div className={styles.modal_main_box}>
      {state.success ? (
        <div
          className={styles.successContainer}
          style={{
            height: contentHeight + 'px'
          }}
        >
          <span>
            {translations.general.curriculumPopup.positiveFeedbackText}{' '}
            <span style={{ fontSize: '21px', paddingTop: '2px' }}>📧</span>
          </span>
        </div>
      ) : (
        <div
          className={styles.fields_box}
          id="email-form"
          name="email-form"
          data-name="Email Form"
          ref={modalContentRef}
        >
          <h5 className={styles.title}>
            {translations.general.curriculumPopup.title}
          </h5>
          <div className={styles.main_fields_box}>
            <input
              onChange={(e) => {
                setState({ email: e.target.value });
              }}
              value={state.email}
              type="email"
              className={
                'form-input form-input-large w-input ' + styles.input_field
              }
              name="email"
              placeholder="Email"
            />
            <input
              onClick={onSubmitHandler}
              type="submit"
              value={translations.general.curriculumPopup.submitBtnText}
              className={
                'button button-large form-row-button w-button ' +
                styles.input_field
              }
            />
          </div>
          <div className={styles.checkOption}>
            <Checkbox
              color="primary"
              checked={state.accept_marketing}
              onChange={setState.bind(null, {
                accept_marketing: !state.accept_marketing
              })}
            />
            <span className={styles.terms}>
              {translations.general.marketingValidationText}
            </span>
          </div>
          <div className={styles.checkOption}>
            <Checkbox
              color="primary"
              checked={state.accept_terms}
              onChange={setState.bind(null, {
                accept_terms: !state.accept_terms
              })}
            />
            <span className={styles.terms}>
              {translations.general.termsCheckbox.termsText1}{' '}
              <a
                className={styles.link}
                target="_blank"
                href={translations.general.termsAndConditionsLink}
              >
                {translations.general.termsCheckbox.termsText2}
              </a>{' '}
              {translations.general.termsCheckbox.termsText3}{' '}
              <a
                target="_blank"
                className={styles.link}
                href={translations.general.privacyPolicyLink}
              >
                {translations.general.termsCheckbox.termsText4}
              </a>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default GetCurriculum;
