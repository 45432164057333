import React from 'react';
import ItemPlayer from './components/ItemPlayer/ItemPlayer';
import styles from './ItemContainer.module.css';

function ItemContainer({ toggleMenu, item, type }) {
  const classes = [
    styles.ItemContainer,
    type === 'video' ? styles.VideoContainer : styles.NoteContainer
  ].join(' ');

  return (
    <div className={classes}>
      {/* <ItemNav toggleMenu={toggleMenu} /> */}
      <ItemPlayer item={item} type={type} />
    </div>
  );
}

export default ItemContainer;
