import React from 'react';
import styles from './MentorCardsList.module.css';

export default function MentorCardsList({ mentors }) {
  return mentors.map((mentor, i) => {
    return <ImgMediaCard card={mentor} />;
  });
}

const ImgMediaCard = ({ card }) => {
  return (
    <div className={styles.card}>
      <img className={styles.card__img} alt={card.name} src={card.img} />
      <div className={styles.card__body}>
        <h4 className={styles.card__title}>{card.name}</h4>
        <p className={styles.card__text}>{card.description}</p>
      </div>
    </div>
  );
};
