import React, { useEffect } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { navigate } from 'gatsby';

const FAQs = ({ FAQsArray = [] }) => {
  useEffect(() => {
    // add redirect navigation to faq price link
    const priceLink = document.querySelector('#faq_price_redirect');

    priceLink.addEventListener('click', () => {
      const { origin, search, pathname } = window.location;

      navigate(pathname + search + '#course-price');
    });
  }, []);

  return FAQsArray.map((faq) => {
    return (
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p
            style={{
              marginBottom: '5px',
              fontSize: '1.19rem',
              fontWeight: '500'
            }}
          >
            {faq.question}
          </p>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <p
            style={{
              marginBottom: '5px',
              color: 'rgb(122 122 122 / 83%)',
              marginLeft: '26px',
              fontSize: '1.1rem',
              lineHeight: '1.7rem'
            }}
          >
            {faq.renderAsHTML ? (
              <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
            ) : (
              faq.answer
            )}
          </p>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  });
};

export default FAQs;
