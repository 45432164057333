import React from "react";
import styles from "./Spinner.module.css";

const spinner = props => (
    <div className={styles.spinnerContainer}>
        <div className={styles.spinner}>
            <div>
                <div className={styles.innerSpinner} />
            </div>
            <div>
                <div className={styles.innerSpinner} />
            </div>
            <div>
                <div className={styles.innerSpinner} />
            </div>
            <div>
                <div className={styles.innerSpinner} />
            </div>
            <div>
                <div className={styles.innerSpinner} />
            </div>
            <div>
                <div className={styles.innerSpinner} />
            </div>
            <div>
                <div className={styles.innerSpinner} />
            </div>
            <div>
                <div className={styles.innerSpinner} />
            </div>
            <div>
                <div className={styles.innerSpinner} />
            </div>
            <div>
                <div className={styles.innerSpinner} />
            </div>
            <div>
                <div className={styles.innerSpinner} />
            </div>
            <div>
                <div className={styles.innerSpinner} />
            </div>
        </div>
    </div>
);

export default spinner;
