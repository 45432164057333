import axios from 'axios';
import { api_base_url } from '../../../data/client';
import is_email_valid from '../../../utils/is_email_valid';
// import { navigate } from 'gatsby';

const signupForCurriculum = async ({
  state,
  setState,
  setFeedbackState,
  translations,
  lang
}) => {
  const translatedErrors = translations.general.errors;

  const { email, accept_marketing } = state;

  try {
    if (!email) {
      setFeedbackState({
        isActive: true,
        isError: true,
        isLoading: false,
        message: translatedErrors.provideEmail
      });
    } else if (!is_email_valid(email)) {
      setFeedbackState({
        isActive: true,
        isError: true,
        isLoading: false,
        message: translatedErrors.provideValidEmail
      });
    } else if (!state.accept_terms) {
      setFeedbackState({
        isActive: true,
        isError: true,
        isLoading: false,
        message: translatedErrors.acceptTerms
      });
    } else {
      setFeedbackState({
        isActive: true,
        isError: false,
        isLoading: true
      });

      const res = await axios({
        url: `${api_base_url}/courses/subscribe/curriculum`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        },
        data: JSON.stringify({ email, accept_marketing, lang })
      });

      if (!res.data.error) {
        // Needed for pixel to measure conversion
        // let comment for now, since it scroll ups the page, might hurt conversion
        // navigate('?curriculum-signup-success=true');

        setState({ success: true });

        setFeedbackState({
          isActive: true,
          isError: false,
          isLoading: false,
          message: translatedErrors.submited
        });
      } else {
        setFeedbackState({
          isActive: true,
          isError: true,
          isLoading: false,
          message: translatedErrors.unexpectedError
        });
      }
    }
  } catch (err) {
    setFeedbackState({
      isActive: true,
      isError: true,
      isLoading: false,
      message: translatedErrors.unexpectedError
    });
  }
};

export default signupForCurriculum;
