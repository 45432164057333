import React, { useContext } from 'react';
import styles from './DailyScheduleTable.module.css';
import { LangContext } from '../../containers/context/LangContext';

const DailyScheduleTable = () => {
  const { translations, lang } = useContext(LangContext);

  return (
    <div className={styles.table}>
      <div className={styles.table__header}>
        <h3 className={styles.table__headerTitle}>
          {translations.howItWorksSection.tableTitle}
        </h3>
        <p className={styles.table__headerSubtitle}>
          {translations.howItWorksSection.tableSubTitle}
        </p>
      </div>
      <div className={styles.table__body}>
        <div className={styles.table__row}>
          <div className={'flex alignX alignY ' + styles.col1}>
            <div className={styles.timeTitle}>
              {translations.howItWorksSection.tableLine1Time_1}
            </div>
            <div className={styles.timeText}>
              {translations.howItWorksSection.tableLine1Time_2}
            </div>
            <div className={styles.timeTitle}>
              {translations.howItWorksSection.tableLine1Time_3}
            </div>
            <div className={styles.timeText}>
              {translations.howItWorksSection.tableLine1Time_4}
            </div>
          </div>
          <div className={styles.col2}>
            <h4 className={styles.col2__title}>
              {translations.howItWorksSection.tableLine1Title}
            </h4>
            <p className={styles.col2__text}>
              {translations.howItWorksSection.tableLine1Text}
            </p>
          </div>
        </div>
        <div className={styles.table__row}>
          <div className={'flex alignX alignY ' + styles.col1}>
            <div className={styles.timeTitle}>
              {translations.howItWorksSection.tableLine2Time_1}
            </div>
            <div className={styles.timeText}>
              {translations.howItWorksSection.tableLine2Time_2}
            </div>
          </div>
          <div className={styles.col2}>
            <h4 className={styles.col2__title}>
              {translations.howItWorksSection.tableLine2Title}
            </h4>
            <p className={styles.col2__text}>
              {translations.howItWorksSection.tableLine2Text}
            </p>
          </div>
        </div>
        {/* <div className={styles.table__row}>
          <div className={'flex alignX alignY ' + styles.col1}>
            {translations.howItWorksSection.tableLine3Time}
          </div>
          <div className={styles.col2}>
            <h4 className={styles.col2__title}>
              {translations.howItWorksSection.tableLine3Title}
            </h4>
            <p className={styles.col2__text}>
              {translations.howItWorksSection.tableLine3Text}
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DailyScheduleTable;
