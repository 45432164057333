import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function Carrousel({ children, slidesToShow }) {
  // const matches1 = useMediaQuery('(max-width:1122px)');
  // const matches2 = useMediaQuery('(max-width:888px)');
  // const matches3 = useMediaQuery('(max-width:554px)');

  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow /*|| (matches3 ? 1 : matches2 ? 2 : matches1 ? 3 : 4)*/,
    slidesToScroll: 1
  };

  return (
    <div>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
}
