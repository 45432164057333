import React, { createContext } from 'react';

export const LangContext = createContext();

const FeedbackProvider = ({ children, translations, lang }) => {
  return (
    <LangContext.Provider value={{ translations, lang }}>
      {children}
    </LangContext.Provider>
  );
};

export default FeedbackProvider;
