import React from 'react';
import styles from './Avatar.module.css';

const Avatar = ({ label, onClick = () => {} }) => {
  return (
    <div className={styles['avatar']} onClick={onClick}>
      {/* <div className={styles['avatar__text']}>{label}</div> */}
      <div className={styles.innerAvatar}></div>
    </div>
  );
};

export default Avatar;
