import React, { useState } from 'react';

const YhellowButton = (props) => {
  return (
    <div
      className={
        'button bg-primary-4 w-inline-block' +
        (props.size === 'small' ? ' button-small' : ' button-large')
      }
      {...props}
    />
  );
};

export default YhellowButton;
