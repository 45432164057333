import React from 'react';

const Button = (props) => {
  return (
    <button
      className={
        'button w-inline-block' +
        (props.size === 'small' ? ' button-small' : ' button-large')
      }
      {...props}
    />
  );
};

export default Button;
