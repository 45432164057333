

import React from "react";
import styles from './Feedback.module.css';
import { Link } from 'gatsby';
import Button from '../Button';
// import CancelIcon from '@material-ui/icons/Cancel';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import { yhelow } from '@material-ui/core/colors';
import CircularProgress from "@material-ui/core/CircularProgress";
import AnnouncementIcon from '@material-ui/icons/Announcement';

const Feedback = ({ message, button_url, button_text, custom_icon, is_error, isLoading }) => {


    const icon = custom_icon ? custom_icon : <AnnouncementIcon fontSize='large' style={{ color: 'yhelow' }} />;
    
    // is_error ? <CancelIcon fontSize='large' color='error' /> : <CheckBoxIcon fontSize='large' style={{ color: green[500] }} />

   return (
    <div className={styles.main_container}>
        {isLoading ?
            <CircularProgress/>
        : 
        <div className={styles.content_container}>
            { icon }
            <h2 style={{marginTop: '15px'}}>{message}</h2>
            {button_url ? 
                <div>
                    <Link to={button_url}>
                        <Button>{button_text}</Button>
                    </Link>
                </div>
            : null }
        </div> }
    </div> 
   );
};

export default Feedback;
