
import React from "react";

const Input = (props) => {
    return (
        <input
            className={"form-input form-input-large w-input"}
            {...props}
        />
    )
}

export default Input;
