const isProduction = process.env.GATSBY_IS_PRODUCTION ? true : false;
const api_domain = process.env.GATSBY_API_DOMAIN;
const api_base_url = isProduction
  ? 'https://' + api_domain + '/api'
  : 'http://localhost:3000/api';

const flagsData = {
  pt: '/flags/portugal.png',
  en: '/flags/united-states.png'
};

module.exports = {
  api_base_url,
  flagsData
};
