import React, { useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import styles from './Popper.module.css';

const Popper = ({
  TriggerComponent,
  children,
  width = '177px',
  top = '50px',
  height = 'auto'
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.pickerWrap} onClick={setIsOpen.bind(null, true)}>
      {TriggerComponent}
      {isOpen ? (
        <div
          className={styles.optionsPopup__wrap}
          style={{ width, top, height }}
        >
          <ClickAwayListener onClickAway={setIsOpen.bind(null, false)}>
            <div className={styles.options__popUp}>{children}</div>
          </ClickAwayListener>
        </div>
      ) : null}
    </div>
  );
};

export default Popper;
