import React from 'react';

const ContactUsForm = ({
  state,
  onChangeField = () => {},
  onSubmitHandler = () => {},
  translations
}) => {
  const { email, message, name } = state;

  return (
    <>
      <h2>{translations.contactSection.title}</h2>
      <div className="form-block w-form">
        <form
          onSubmit={onSubmitHandler}
          id="email-form"
          name="email-form"
          data-name="Email Form"
          className="form-grid-vertical"
          method="POST"
        >
          <div className="w-layout-grid form-row-grid-halves">
            <input
              value={name}
              onChange={onChangeField.bind(null, { propName: 'name' })}
              type="text"
              className="form-input form-input-large w-input"
              maxlength="256"
              name="name"
              data-name="Name"
              placeholder={translations.contactSection.namePlaceholder}
              id="name"
              required="true"
            />
            <input
              value={email}
              onChange={onChangeField.bind(null, { propName: 'email' })}
              type="email"
              className="form-input form-input-large w-input"
              maxlength="256"
              name="email"
              data-name="Email"
              placeholder={translations.contactSection.emailPlaceholder}
              id="email"
              required="true"
            />
          </div>
          <textarea
            value={message}
            onChange={onChangeField.bind(null, { propName: 'message' })}
            placeholder={translations.contactSection.messagePlaceholder}
            maxlength="5000"
            id="Message"
            name="Message"
            data-name="Message"
            className="form-input text-area w-input"
            required="true"
          ></textarea>
          <div style={{ maxWidth: '140px' }}>
            <input
              type="submit"
              value={translations.contactSection.submitBtnText}
              data-wait="Please wait..."
              className="button button-large w-button"
            />
          </div>
          {/* <div className="small-text">*&nbsp;Add some disclaimer text here.</div> */}
        </form>
      </div>
    </>
  );
};

export default ContactUsForm;
