
import isBrowser from './is_client_browser';


const addTargetBlankToAnchors = (className) => {

    if(isBrowser()) {
        const anchors = document.querySelectorAll(`.${className} a`);
        anchors.forEach((ele) => {
            ele.setAttribute('target', '_blank');
        })
    }

    else {
        return null;
    }
    
}

export default addTargetBlankToAnchors;