
import React, { useEffect } from "react";
import styles from "./ItemPlayer.module.css";
import ReactMarkdown from 'react-markdown'
import addTargetBlankToAnchors from '../../../../utils/addTargetBlankToAnchors'

const classDomIndentifier = 'noteLectureIndentifier';

function ItemPlayer({ item, type }) {
    if (type === "video") {
        return (
            <iframe
                className={styles.ItemPlayer}
                src={item}
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                allow="autoplay; encrypted-media"
            />
        );
    } else {
        return (
            <NoteLecture styles={styles} item={item}/>
        );
    }
}

export default ItemPlayer;



const NoteLecture = ({ styles, item }) => {

    useEffect(() => {   
        addTargetBlankToAnchors(classDomIndentifier);
    }, [item])

    return (
        <div className={styles.NotePlayer +  ' ' + classDomIndentifier}>
            <ReactMarkdown>{item}</ReactMarkdown>
        </div>
    )
}

