import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import styles from './ClickPopUp.module.css';

const ClickPopUp = ({ children, ModalComponent }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {isOpen ? (
        <Modal
          className={styles.modal}
          open={isOpen}
          onClose={setIsOpen.bind(null, false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {ModalComponent}
        </Modal>
      ) : null}
      <div onClick={setIsOpen.bind(null, true)}>{children}</div>
    </>
  );
};

export default ClickPopUp;
