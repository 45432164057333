import React, { useEffect, useContext } from 'react';
import useSetState from '../../utils/useSetState';
import is_client_browser from '../../utils/is_client_browser';
import { LangContext } from '../../containers/context/LangContext';

const CookiesBar = () => {
  const { translations } = useContext(LangContext);

  const [state, setState] = useSetState({
    isOpen: false
  });

  useEffect(() => {
    if (!hasAcceptedBefore()) {
      setState({ isOpen: true });
    }
  }, []);

  const onClickHandle = () => {
    addAcceptedStateToStorage();
    setState({ isOpen: false });
  };

  return state.isOpen ? (
    <div className="alert-popup">
      <div className="small-text space-right cookies-bar-text">
        {translations.general.cookiesBartText1}{' '}
        <a
          style={{
            color: '#fff',
            textDecoration: 'underline',
            fontWeight: 400
          }}
          target="_blank"
          href={translations.general.privacyPolicyLink}
        >
          {translations.general.cookiesBartText2}
        </a>
      </div>
      <div className="dismiss-alert-popup">
        <div
          onClick={onClickHandle}
          className="button small-icon-button button-outline-white w-inline-block"
        >
          <img
            src="../images/icon-x_1.svg"
            alt=""
            className="small-icon-button-icon"
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default CookiesBar;

const hasAcceptedBefore = () => {
  if (is_client_browser()) {
    if (localStorage.getItem('acceptedTerms') == 'yes') {
      return true;
    }
  }
};

const addAcceptedStateToStorage = () => {
  if (is_client_browser()) {
    localStorage.setItem('acceptedTerms', 'yes');
  }
};
