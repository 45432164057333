import React, { useState } from 'react';
import styles from './CountryInput.module.css';
import { getData } from 'country-list';

const CountryInput = ({ onChange, value, defaultPlaceholder }) => {
  const [countries] = useState(getSortedCountries());

  return (
    <select className={styles.select} onChange={onChange} value={value}>
      <option value="">{defaultPlaceholder || ''}</option>
      {countries.map((country) => {
        return <option value={country.code}>{country.name}</option>;
      })}
    </select>
  );
};

export default CountryInput;

const getSortedCountries = () => {
  return getData().sort(function (a, b) {
    return a.name
      .toString()
      .localeCompare(b.name.toString(), 'en', { numeric: true });
  });
};
