import React from 'react';

export default function getTestimonialCardList({ cards }) {
  return cards.map((card, i) => {
    return (
      <div key={i} className="quote-card bg-primary-3">
        <div className="quote-card-body">
          <div>
            <img
              src={card.avatar_path}
              alt={card.name + ' testemunho'}
              className="avatar"
            />
          </div>
          <div className="quote-card-text">
            <h4 className="large-text testimonial-text">{card.review}</h4>
            <div className="small-text small-text--testimonial">
              {card.name}
            </div>
          </div>
        </div>
      </div>
    );
  });
}
