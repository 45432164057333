import React from 'react';
import styles from './CheckItem.module.css';

const CheckItem = ({
  text,
  checkColor = '#232323',
  checkBgColor = 'white',
  classNameBullet
}) => {
  return (
    <div className={styles.item}>
      <div
        style={{ backgroundColor: checkBgColor, color: checkColor }}
        className={styles.checkIconWrap + ' checklist-circle-small bg-white'}
      >
        <svg width="1em" height="1em" viewBox="0 0 13 10" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.3268 9.4001L0.0348511 5.16933L1.2366 3.98471L4.3268 7.03087L10.8506 0.600098L12.0523 1.78471L4.3268 9.4001Z"
            fill="#232323"
          />
        </svg>
      </div>
      <div className={classNameBullet + ' ' + styles.item__text}>{text}</div>
    </div>
  );
};

export default CheckItem;
